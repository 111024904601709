<template>
    <div class="bg-gray-600 grid grid-cols-1 gap-[1px]">
        <div
            v-for="(item, i) in custom.filter((x) => x.permission && x.type !== 'project-folder')"
            :key="`custom-context-item-${i}`"
            class="button-bg"
        >
            <delete-modal
                v-if="item.type === 'delete'"
                :button-is-icon="false"
                :button-block="true"
                button-color="tertiary"
                @deleteData="() => item.clickHandler()"
            >
                <i :class="`las ${item.icon} mr-2`" />
                {{ item.label }}
            </delete-modal>
            <v-btn v-else-if="item.url" block left color="tertiary" class="px-2" :to="item.url">
                <i :class="`las ${item.icon} mr-2`" />
                {{ item.label }}
            </v-btn>
            <v-btn v-else-if="item.clickHandler" block left color="tertiary" class="px-2" @click="item.clickHandler">
                <i :class="`las ${item.icon} mr-2`" />
                {{ item.label }}
            </v-btn>
        </div>

        <div v-if="message && $checkPermission('message.create')" class="button-bg">
            <v-btn
                block
                left
                color="tertiary"
                class="px-2"
                @click="updateSendMessage(true, message.projectId, message.entityId, message.recipient, message.entityTypeId)"
            >
                <i class="las la-envelope mr-2" />
                Send message
            </v-btn>
        </div>
        <div v-if="task && $checkPermission('task.create')" class="button-bg">
            <v-btn
                block
                left
                color="tertiary"
                class="px-2"
                @click="updateTaskVisibility(true, task.projectId, task.entityId, task.assignee, task.entityTypeId)"

            >
                <i class="las la-plus-circle mr-2" />
                Create task
            </v-btn>
        </div>
        <div v-if="openProjectFolderButtonData" class="button-bg">
            <v-btn block left color="tertiary" class="px-2" :href="openProjectFolderButtonData.url">
                <i :class="`las ${openProjectFolderButtonData.icon} mr-2`" />
                {{ openProjectFolderButtonData.label }}
            </v-btn>
        </div>
        <div v-if="linkWithCopy" class="button-bg">
            <v-btn block left color="tertiary" class="px-2" @click="$copyToClipboard(linkWithCopy)">
                <i class="las la-copy mr-2" />
                Copy link
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionContextMenu',
    props: {
        custom: {
            type: Array,
            required: false,
            default: () => [],
            validator: (value) => {
                if (!value.length) {
                    return true;
                }
                return value.some((x) => {
                    return !(x.permission === undefined || !x.label || (!x.url && !x.clickHandler) || !x.icon);
                });
            },
        },
        task: {
            type: Object,
            required: false,
            default: null,
            validator: (value) => {
                return !(!value.entityId || typeof value.entityId !== 'number' || !value.entityTypeId) &&
                    !(value.assignee && typeof value.assignee !== 'number');
            },
        },
        message: {
            type: Object,
            required: false,
            default: null,
            validator: (value) => {
                return !(!value.entityId || typeof value.entityId !== 'number' || !value.entityTypeId) &&
                    !(value.recipient && typeof value.recipient !== 'number');
            },
        },
        linkWithCopy: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        openProjectFolderButtonData() {
            return this.custom.find((x) => x.type === 'project-folder');
        },
    },
    methods: {
        updateSendMessage(state, projectID, entityId, recipient, type) {
            this.$store.dispatch('messages/setShowNewMessage', { state, projectID, entityId, recipient, type });
        },
        updateTaskVisibility(state, project, entity, assignee, type) {
            this.$store.dispatch('tasks/setShowNewTask', { state, project, entity, assignee, type });
        },
    },
};
</script>

<style scoped>
.button-bg {
    @apply bg-secondary-light dark:bg-dark-800 py-1 first:pt-0 last:pb-0;
}
</style>
