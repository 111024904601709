<template>
    <aside
        class="sidebar shadow-sidebar transition-all w-18 fixed bg-white dark:bg-dark-850 h-screen top-0 left-0 max-h-screen z-20"
    >
        <div class="overflow-hidden">
            <div class="sidebar-inside w-58">
                <logo />

                <div class="px-4">
                    <hr
                        class="border-gray-150 dark:border-dark-750 transition-all w-full"
                        :class="{ 'w-10': !$store.state.preferences.isSidebarOpen }"
                    />
                </div>

                <projects-header v-if="$checkPermission('project.read')" />

                <projects-list v-if="$checkPermission('project.read')" />

                <management-and-system />
            </div>
        </div>

        <v-btn class="absolute top-6 text-gray-300 toggle-sidebar" icon :ripple="false" @click="toggleSidebar">
            <i class="las la-angle-double-left" />
        </v-btn>
    </aside>
</template>

<script>
import Logo from '@/components/Layouts/Sidebar/Logo.vue';
import ProjectsHeader from '@/components/Layouts/Sidebar/ProjectsHeader.vue';
import ProjectsList from '@/components/Layouts/Sidebar/ProjectsList.vue';
import ManagementAndSystem from '@/components/Layouts/Sidebar/ManagementAndSystem.vue';

export default {
    name: 'TheSidebar',
    components: {
        Logo,
        ProjectsHeader,
        ProjectsList,
        ManagementAndSystem,
    },
    methods: {
        toggleSidebar() {
            this.$store.dispatch('preferences/toggleSidebar');
            this.$store.commit('setSidebarAdminState', false);
        },
    },
};
</script>

<style lang="postcss" scoped>
.sidebar {
    .sidebar-open & {
        @apply w-58;
    }

    .fullscreen & {
        @apply transform -translate-x-full;
    }

    &-menu {
        max-height: calc(100vh - 15rem);

        &-alt {
            &:before {
                content: '';
            }
        }

        &.admin-open {
            max-height: calc(100vh - 25rem);
        }

        .sidebar-project-link {
            &.nuxt-link-active {
                .sidebar-icon {
                    @apply border-4 border-white;
                }
            }
        }
    }

    &-pin-menu {
        .shadow-line {
            @apply bg-gradient-to-b;
            @apply block relative w-full h-6 z-10 -mt-6;

            &-before {
                @apply -mt-6 transform translate-y-6;
                @apply from-white dark:from-dark-850 to-transparent;

                .dark-mode & {
                    @apply from-dark-850 to-transparent;
                }
            }

            &-after {
                @apply -mt-6;
                @apply from-transparent to-white;

                .dark-mode & {
                    @apply from-transparent to-dark-850;
                }
            }
        }
    }
}

.toggle-sidebar {
    @apply right-0 transition-all;
    @apply bg-transparent rounded-full;
    @apply dark-hover:bg-transparent text-secondary dark:text-white !important;

    &:after {
        content: '';
        @apply absolute top-0 right-0 h-full w-1/2 bg-white dark:bg-dark-850 rounded-r-full;
    }

    .main:not(.sidebar-open) & {
        @apply opacity-0 -right-4;

        i {
            @apply transition-all ml-1;
            transform: scale(-1, 1);
        }
    }
}

.main .sidebar:hover {
    .toggle-sidebar {
        @apply opacity-100;
    }
}
</style>
