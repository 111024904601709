export default async function (arg) {
    // console.log('Navigating!');
    // console.log(arg);
    const params = arg.params;
    const app = arg.app;
    const store = arg.store;
    const route = arg.route;
    const error = arg.error;

    // console.log(arg.route.fullPath);

    let userPermissions = app.store.getters['users/me/getPermissions'];

    // Save the timestamp of the navigation to use for all fetches
    const time = (new Date()).getTime();
    const requests = [];
    let navigated = 0;
    const updateNavMessage = () => {
        navigated++;
        store.commit('project/setNavigatingMessage', `${Math.floor(navigated / requests.length * 100)}%`);
    }

    // Refresh fetchTimes immediately to stop any possible ongoing navigation
    // console.log('Updating fetchTimes...');
    store.dispatch('project/setAllFetchTimes', time);

    // Check if the user is logged in already
    if (!userPermissions) {
        // console.log('Fetching user and config...');
        requests.push(store.dispatch('users/me/setMe').then(updateNavMessage));
        requests.push(store.dispatch('config/fetchConfig').then(updateNavMessage));
    }

    // Load all relevant data concurrently
    if (route && route.name && route.name.includes('projects-id') && params.id) {
        const activeProject = app.store.getters['project/getActiveProject'];
        const activeSequences = app.store.getters['project/getActiveProjectSequences'];
        const activeShot = app.store.getters['project/getActiveShot'];
        const activePasses = app.store.getters['project/getActivePasses'];

        // Load new project / sequences if required
        if (!activeProject?.id || activeSequences.length === 0 || String(activeProject?.id) !== params.id) {
            // console.log(`Fetching project, team, and sequences... (P: ${activeProject?.id} -> ${params.id})`);
            requests.push(store.dispatch('project/fetchActiveProject', {id: params.id, time}).then(updateNavMessage));
            requests.push(store.dispatch('project/fetchActiveProjectMembers', {id: params.id, time}).then(updateNavMessage));
            requests.push(store.dispatch('project/fetchSequences', {id: params.id, time}).then(updateNavMessage));

            requests.push(store.dispatch('project/fetchProjectColorSpaces', {id: params.id}).then(updateNavMessage));
        }

        // Load new shot / passes if required
        if (route.name.includes('shots-shot') && params.shot) {
            if (!activeShot?.id || Object.keys(activePasses).length === 0 || String(activeShot?.id) !== params.shot) {
                // console.log(`Fetching shot and passes... (S: ${activeShot?.id} -> ${params.shot})`);
                requests.push(store.dispatch('project/fetchActiveShot', {id: params.shot, time}).then(updateNavMessage));
                requests.push(store.dispatch('project/fetchPasses', {id: params.shot, type: 'comp', time}).then(updateNavMessage));
                requests.push(store.dispatch('project/fetchPasses', {id: params.shot, type: 'cg', time}).then(updateNavMessage));
                requests.push(store.dispatch('project/fetchPasses', {id: params.shot, type: 'image', time}).then(updateNavMessage));
                requests.push(store.dispatch('project/fetchPasses', {id: params.shot, type: 'audio', time}).then(updateNavMessage));
            }
        }
    }

    if (requests.length) {
        store.commit('project/setNavigating', true);
        store.commit('project/setNavigatingMessage', "");
        await Promise.all(requests).catch((e) => {
            store.commit('project/setNavigatingSilent', false);
            throw e;
        });
        // Check fetchTimes, make sure they match
        if (Object.keys(store.state.project.fetchTimes).every(x => store.state.project.fetchTimes[x] === time)) {
            // console.log('Navigation done! Setting data...');
            store.commit('project/setNavigating', false);
            if (params.id && params.shot) {
                store.commit('routing/setSavedProjectShotRoute', {
                    projectID: params.id,
                    shotRoute: '/' + params.shot,
                });
            }
        } else {
            // console.log('Outdated navigation! Canceling!');
            store.commit('project/setNavigatingSilent', false);
            return;
        }
        userPermissions = store.getters['users/me/getPermissions'];
    }

    const userPermissionHandles = app.$clone(userPermissions).map((x) => x.handle);
    const routePermissions = store.getters['permissions/getPermissions'];
    const routeHandler = routePermissions.find((data) => data.route === route.name);

    // Test user's permissions
    if (!routeHandler || routeHandler.handle.every((x) => userPermissionHandles.includes(x))) {
        // Success
        return;
    }

    // Otherwise throw forbidden error
    const missingPermissions = routeHandler.handle.filter((x) => !userPermissionHandles.includes(x));
    if (route.name.includes('projects-id')) {
        store.commit('routing/setSavedProjectTabRoute', {
            projectID: route.params.id,
            tabRoute: null,
        });
    }
    return error({
        statusCode: 403,
        message: `Access denied (${missingPermissions.join(', ')} permission required)`,
    });
}
