<template>
    <div class="sticky top-0 h-0" :class="[!sidebarState ? 'pl-18' : 'pl-sidebar', showLoading ? 'z-15' : '-z-5']">
        <div
            class="w-full bg-dark-850 text-gray-500 flex flex-column justify-center items-center gap-6"
            :class="[showLoading ? 'opacity-50 dark:opacity-80' : 'opacity-0']"
            style="height: 100vh; transition: 1s all ease;"
        >
            <i class="animate-spin las la-circle-notch firefox-padding" style="font-size: 10rem !important;" />
            <span>{{navMessage}}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Loading',
    components: {
    },
    data() {
        return {
            showLoading: false,
            delay: 1500,
        }
    },
    computed: {
        ...mapGetters({
            sidebarState: 'preferences/getSidebarState',
            isNavigating: 'project/getNavigating',
            navMessage: 'project/getNavigatingMessage',
        }),
    },
    watch: {
        isNavigating(e) {
            if (e) {
                setTimeout(() => {
                    if (this.isNavigating) {
                        this.showLoading = true;
                    }
                }, this.delay);
            } else {
                this.showLoading = false;
            }
        }
    },
    methods: {
    },
};
</script>

<style>
.-z-5 {
    z-index: -5;
}
html.firefox .firefox-padding {
    padding-bottom: 2px;
}
</style>